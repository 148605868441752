<template>
  <el-dialog
    class="my-dialog"
    width="480px"
    center
    :visible.sync="dialogVisible"
    :title="'订单支付'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" label-position="top" @submit.native.prevent>
      <el-form-item label="请输入6位数字支付密码" prop="password" style="width: 100%" class="ipt-item">
        <el-input
          v-model.trim="form.password"
          placeholder="请输入6位数字支付密码"
          :type="typePassword"
          @keyup.enter="disableEnter"
          @input="v => (form.password = v.replace(/[^\d]/g, ''))"
          maxlength="6"
        />
        <i @click="flagPassword = !flagPassword" :class="form.password ? elIconsPassword : ''" class="i-icon"></i>
      </el-form-item>
      <div style="margin-bottom: 20px" v-if="errorNum >= 5">请输入手机号{{ phoneUser }}收到的验证码完成验证</div>
      <el-form-item label="" prop="code" v-if="errorNum >= 5">
        <div class="message-code">
          <el-input v-model.trim="form.code" placeholder="请输入验证码" maxlength="6" @input="v => (form.code = v.replace(/[^\d]/g, ''))"></el-input>
          <el-button :disabled="count > 0 && disabledCode" @click="getVerificationCode">
            {{ count > 0 ? `${count}秒后重新获取` : '获取验证码' }}
          </el-button>
        </div>
        <span class="message-text">支付密码输入不正确，已错误{{ errorNum }}次，请使用短信验证码和支付密码验证完成支付</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class="dialog-footer-btn" :style="!isAllNumber && isSubmit ? 'cursor:pointer; background:#F0314A;' : ''" @click="payBtn">立即支付</div>
    </span>
  </el-dialog>
</template>
<script>
import { orderPayV2, orderPayVouch, payPassErrorCount, getSmsCode } from '@/api/confirmOrder.js'
import { encrypt } from '@/utils/jsencrypt'
export default {
  name: 'PasswordDialog',
  props: {
    show: { type: Boolean, default: false },
    orderNo: { type: String, default: null },
    accountNum: { type: String, default: null },
    orderId: { type: Number, default: null },
    selectVoucherList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          console.log('1111', this.orderNo, this.orderId)
          this.isAllNumber = false
          this.isSubmit = true
          this.form.password = null
          this.form.code = null
          this.getPayPassErrorCount()
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    typePassword() {
      return this.flagPassword ? 'text' : 'password'
    },
    elIconsPassword() {
      return this.flagPassword ? 'el-icon-view' : 'el-icon-cloce-eye'
    },
    phoneUser() {
      if (this.accountNum) {
        return this.accountNum.substring(0, 3) + '******' + this.accountNum.substring(9)
      } else {
        return '暂无手机号'
      }
    }
  },
  data() {
    return {
      isAllNumber: false,
      form: {
        password: null,
        code: null
      },
      rules: {
        password: [
          { required: true, message: '支付密码不能为空', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入六位的支付密码' }
        ],
        code: [
          { required: true, message: '短信验证码不能为空', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入六位的短信验证码' }
        ]
      },
      flagPassword: false,
      isSubmit: true,
      errorNum: 0,
      count: 0,
      disabledCode: false
    }
  },
  methods: {
    disableEnter(event) {
      event.preventDefault()
    },
    payBtn() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('---------', this.form.password, encrypt(this.form.password))
          console.log('111', this.selectVoucherList.length, this.selectVoucherList)
          this.isSubmit = false
          if (this.selectVoucherList.length > 0) {
            this.getPurchaseVouch()
          } else {
            this.getPurchase()
          }
        }
      })
    },
    // 支付接口 2000支付成功 5000支付失败 2401支付密码错误 2402余额不足
    async getPurchase() {
      try {
        let paramsMoney = {
          orderNo: this.orderNo,
          terminalType: 'webPc',
          payWay: 4, // 0:支付宝 1 微信 3代币
          mode: 'h5', // native 、app、h5，对应二维码、手机App和h5网页
          pwd: encrypt(this.form.password), // 加密后的支付密码
          smsCode: this.form.code || '' // 短信验证码
        }
        let res = await orderPayV2(paramsMoney)
        console.log('🚀 ~ res:', res)
        if (res.data.data == '2000') {
          this.$router.push({ path: '/paySucceed' })
        } else if (res.data.data == '2401') {
          this.$message.error('支付密码错误')
          this.form.password = null
          this.form.code = null
          this.getPayPassErrorCount()
        } else if (res.data.data == '2402') {
          this.$message.error('余额不足')
          this.form.password = null
          this.form.code = null
        } else if (res.data.data == '5000') {
          this.$message.error('支付失败')
          this.form.password = null
          this.form.code = null
        }
        this.isSubmit = true
      } catch (error) {
        console.log('🚀 ~ error:', error)
        this.$message.error(error.msg || '支付失败,订单可能已失效或已被支付')
        this.form.password = null
        this.form.code = null
        this.isSubmit = true
      }
    },
    async getPurchaseVouch() {
      try {
        let paramsVoucher = {
          orderNo: this.orderNo,
          terminalType: 'webPc',
          voucherList: this.selectVoucherList,
          pwd: encrypt(this.form.password), // 加密后的支付密码
          smsCode: this.form.code || '' // 短信验证码
        }
        let res = await orderPayVouch(paramsVoucher)
        console.log('🚀 ~ res:', res)
        this.isSubmit = true
        this.$router.push({ path: '/paySucceed' })
      } catch (error) {
        this.getPayPassErrorCount()
        this.$message.error(error.msg || '兑换失败！')
        this.form.password = null
        this.isSubmit = true
      }
    },
    // 获取支付密码错误次数
    async getPayPassErrorCount() {
      try {
        let res = await payPassErrorCount()
        this.errorNum = res.data
      } catch (error) {
        console.log('🚀 ~ payPassErrorCount---error:', error)
      }
    },
    // 获取短信验证码
    async getVerificationCode() {
      try {
        this.disabledCode = true
        let codeParams = {
          telephone: this.accountNum,
          type: 12
        }
        this.$refs.form.clearValidate('code')
        let res = await getSmsCode(codeParams)
        this.$message.success('验证码发送成功')
        this.count = 60
        let timer = setInterval(() => {
          if (this.count > 0) {
            this.count -= 1
          } else {
            this.disabledCode = false
            clearInterval(timer)
          }
        }, 1000)
      } catch (error) {
        this.disabledCode = false
        this.$message.error(error.msg || '验证码发送失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-input-number .el-input__inner {
  padding: 0;
}
.ipt-item {
  position: relative;
  .i-icon {
    position: absolute;
    top: 33%;
    right: 15px;
  }
}
.password-dialog-form {
  width: calc(100% - 100px);
  margin: auto;
}
.password-box {
  display: flex;
  justify-content: space-around;

  .password-box-item {
    width: calc(100% / 7);
    position: relative; // 需要相对定位以便伪元素正确定位
    .password-input {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
    .password-input-active::before {
      content: ''; // 使用圆点字符
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      background: black;
      border-radius: 50px;
      transform: translate(-50%, -50%);
      font-size: 18px; // 圆点的大小
      color: black; // 圆点的颜色
      pointer-events: none; // 确保圆点不会干扰输入
      z-index: 9;
    }
    ::v-deep .el-input__inner {
      color: transparent; // 隐藏输入的数字
    }
    ::v-deep .el-input-number__decrease {
      display: none;
    }
    ::v-deep .el-input-number__increase {
      display: none;
    }
  }
}
.message-code {
  border: 1px solid #dcdfe6;
  display: flex;
  ::v-deep .el-input__inner {
    border: none;
  }
  ::v-deep .el-button {
    border: none;
    &:hover {
      background: transparent;
    }
    &:focus {
      background: transparent;
    }
  }
}
.message-text {
  display: block;
  line-height: 20px;
  color: #f56c6c;
  margin-top: 5px;
}
.dialog-footer-btn {
  width: 260px;
  height: 50px;
  margin: auto;
  color: #fff;
  line-height: 50px;
  text-align: center;
  background: #acacac;
  border-radius: 4px;
  cursor: no-drop;
}
</style>
