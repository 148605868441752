<template>
  <el-dialog class="my-dialog" width="760px" center :visible.sync="dialogVisible" :title="'可用权益'">
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="'不可叠加' + '(' + appendNoItemList.length + ')'" name="0"></el-tab-pane>
        <el-tab-pane :label="'可叠加' + '(' + appendItemList.length + ')'" name="1"></el-tab-pane>
      </el-tabs>

      <!-- 不可叠加 -->
      <div class="discount-list" v-if="activeName == '0'">
        <div
          class="discount-item"
          v-for="(item, index) in appendNoItemList"
          :key="index"
          :class="item.isShowRemark == true ? 'discount-item-height' : ''"
          :style="item.discountType == 2 ? 'background: linear-gradient(90deg, #FFE0BF 0%, #FEEEDB 100%);' : ''"
          @click="clickDiscountNoItem(item, index)"
        >
          <div class="mask-box" v-show="isShowMask && activeName == '0' && !item.isSub"></div>
          <div class="discount-item-top" :style="item.discountType == 2 ? 'border-bottom: 1px dashed #C2925A;' : ''">
            <div class="discount-iocn">
              <img src="../../../assets/pingtai-icon.png" alt="" v-if="item.discountType == 1" />
              <img src="../../../assets/qiye-icon.png" alt="" v-if="item.discountType == 2" />
            </div>
            <div class="discount-info">
              <div class="discount-title" :style="item.discountType == 2 ? 'color: #472200;' : ''">{{ item.discountName }}</div>
              <div class="discount-time" :style="item.discountType == 2 ? 'color: #BB8C6B;' : ''">有效期：{{ item.endTime }}</div>
              <div class="discount-num" :style="item.discountType == 2 ? 'color: #A6570E;' : ''">
                {{ item.discountNum / 10 }}
                <span>折</span>
              </div>
              <span class="act-icon el-icon-success" v-if="item.isSub == true"></span>
              <span class="act-icon-no" v-else></span>
            </div>
          </div>
          <div :class="['discount-item-btm', item.isShowRemark && item.discountType == 1 && 'expand-plantform',item.isShowRemark && item.discountType == 2 && 'expand-company']">
            <div class="remark" :class="item.isShowRemark == true ? '' : 'remark-act'" :style="item.discountType == 2 ? 'color: #BB8C6B;' : ''">
              {{ item.remark }}
            </div>
            <template v-if="item.remark !== ''">
              <span class="el-icon-arrow-up" v-if="item.isShowRemark == true" @click.stop="hideNoRemark(index)"></span>
              <span class="el-icon-arrow-down" v-else @click.stop="showNoRemark(index)"></span>
            </template>
          </div>
        </div>
        <div style="color: #4a9dff; height: 100px; width: 100%; line-height: 100px; text-align: center" v-show="appendNoItemList.length == 0">
          暂无相关权益~
        </div>
      </div>

      <!-- 可叠加 -->
      <div class="discount-list" v-if="activeName == '1'">
        <div
          class="discount-item"
          v-for="(item, index) in appendItemList"
          :key="index"
          :class="item.isShowRemark == true ? 'discount-item-height' : ''"
          :style="item.discountType == 2 ? 'background: linear-gradient(90deg, #FFE0BF 0%, #FEEEDB 100%);' : ''"
          @click="clickDiscountItem(item, index)"
        >
          <div class="discount-item-top" :style="item.discountType == 2 ? 'border-bottom: 1px dashed #C2925A;' : ''">
            <div class="discount-iocn">
              <img src="../../../assets/pingtai-icon.png" alt="" v-if="item.discountType == 1" />
              <img src="../../../assets/qiye-icon.png" alt="" v-if="item.discountType == 2" />
            </div>
            <div class="discount-info">
              <div class="discount-title" :style="item.discountType == 2 ? 'color: #472200;' : ''">{{ item.discountName }}</div>
              <div class="discount-time" :style="item.discountType == 2 ? 'color: #BB8C6B;' : ''">有效期：{{ item.endTime }}</div>
              <div class="discount-num" :style="item.discountType == 2 ? 'color: #A6570E;' : ''">
                {{ item.discountNum / 10 }}
                <span>折</span>
              </div>
              <span class="act-icon el-icon-success" v-if="item.isSub == true"></span>
              <span class="act-icon-no" v-else></span>
            </div>
          </div>
          <div :class="['discount-item-btm', item.isShowRemark && item.discountType == 1 && 'expand-plantform',item.isShowRemark && item.discountType == 2 && 'expand-company']">
            <div class="remark" :class="item.isShowRemark == true ? '' : 'remark-act'" :style="item.discountType == 2 ? 'color: #BB8C6B;' : ''">
              {{ item.remark }}
            </div>
            <template v-if="item.remark !== ''">
              <span class="el-icon-arrow-up" @click.stop="hideRemark(index)" v-if="item.isShowRemark == true"></span>
              <span class="el-icon-arrow-down" @click.stop="showRemark(index)" v-else></span>
            </template>
          </div>
        </div>
        <div style="color: #4a9dff; height: 100px; width: 100%; line-height: 100px; text-align: center" v-show="appendItemList.length == 0">
          暂无相关权益~
        </div>
      </div>
    </div>

    <span
      slot="footer"
      class="dialog-footer"
      v-show="(activeName == '0' && appendNoItemList.length > 0) || (activeName == '1' && appendItemList.length > 0)"
    >
      <div class="dialog-footer-btn" @click="submitDiscount">确认选择</div>
    </span>
  </el-dialog>
</template>
<script>
import { getSelectDiscounts } from '@/api/courseGroupDetail.js'
import { getDiscountPrice } from '@/utils/common.js'
export default {
  name: 'DiscountDialog',
  props: {
    show: { type: Boolean, default: false },
    groupId: { type: Number, default: null },
    totalMoney: { type: String, default: null },
    selectShowList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          console.log('🚀 ~ newVal:', newVal, this.selectShowList)
          this.appendNoItemList.forEach(item => {
            this.$set(item, 'isSub', false)
            this.$set(item, 'isShowRemark', false)
          })
          this.appendItemList.forEach(item => {
            this.$set(item, 'isSub', false)
            this.$set(item, 'isShowRemark', false)
          })
          if (this.selectShowList.length > 0) {
            this.activeName = this.selectShowList[0].appendFlag == 1 ? '1' : '0'
            if (this.activeName == '0') {
              // 回显默认选中的折扣
              this.appendNoItemList.forEach(item => {
                this.selectShowList.forEach(sel => {
                  if (item.discountId == sel.discountId && item.discountType == sel.discountType) {
                    this.$set(item, 'isSub', true)
                  }
                })
              })
              this.selectList = this.appendNoItemList.filter(item => item.isSub == true)
              this.isShowMask = this.appendNoItemList.map(item => item.isSub).some(item => item == true)
              this.appendNoItemList
            } else {
              this.appendItemList.forEach(item => {
                this.selectShowList.forEach(sel => {
                  if (item.discountId == sel.discountId && item.discountType == sel.discountType) {
                    this.$set(item, 'isSub', true)
                  }
                })
              })
              this.selectList = this.appendItemList.filter(item => item.isSub == true)
            }
          }
        }
      }
    },
    totalMoney: {
      handler(newVal) {
        if (newVal) {
          // 修改5693的bug
          // let list = getDiscountPrice(this.appendItemList, this.appendNoItemList, this.groupId, Number(newVal)).showList
          let list = getDiscountPrice(this.selectShowList, [], this.groupId, Number(newVal)).showList
          if (!this.$route.query.isJupm) {
            // 从后台去支付页面跳转进来的不调用该方法
            this.$emit('submitDiscount', list)
            console.log('🚀 ~ totalMoney:', list)
          }
        }
      }
    },
    groupId: {
      handler(newVal) {
        if (newVal) {
          if (!this.$route.query.isJupm) {
            // 从后台去支付页面跳转进来的不调用该方法
            console.log('🚀 ~ groupId----:', newVal)
            this.getSelectDiscounts(newVal)
          }
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  data() {
    return {
      activeName: '0',
      selectList: [], //当前选择的折扣
      appendItemList: [], //当前课程可叠加折扣
      appendNoItemList: [], //当前课程不可叠加折扣
      firstClick: 0,
      isShowMask: false
    }
  },
  methods: {
    handleClick() {
      this.firstClick = this.firstClick + 1
      this.isShowMask = this.appendNoItemList.map(item => item.isSub).some(item => item == true)
      // if (this.activeName == '0') {
      //   this.appendItemList.forEach(item => (item.isSub = false))
      //   this.selectList = this.appendItemList.filter(item => item.isSub == true)
      // } else {
      //   this.appendNoItemList.forEach(item => (item.isSub = false))
      //   this.selectList = this.appendNoItemList.filter(item => item.isSub == true)
      // }
    },
    // 获取商品折扣
    async getSelectDiscounts(id) {
      try {
        let res = await getSelectDiscounts()
        this.appendItemList = res.data.filter(item => item.appendFlag == 1 && item.goodsId == id)
        this.appendNoItemList = res.data.filter(item => item.appendFlag == 0 && item.goodsId == id)
        let list = getDiscountPrice(this.appendItemList, this.appendNoItemList, id, Number(this.totalMoney)).showList
        list = list.sort((a, b) => a.discountNum - b.discountNum)
        this.$emit('getExistDis', this.appendItemList, this.appendNoItemList)
        this.$emit('submitDiscount', list)
        console.log('🚀 ~getSelectDiscounts:', list)
      } catch (error) {}
    },
    // 不可叠加的
    clickDiscountNoItem(item, index) {
      this.firstClick = this.firstClick + 1
      this.appendNoItemList.forEach((value, valueIndex) => {
        if (valueIndex == index) {
          value.isSub == true ? (value.isSub = false) : (value.isSub = true)
        } else {
          value.isSub = false
        }
      })
      this.appendItemList.forEach(item => (item.isSub = false))
      this.selectList = []
      this.selectList = this.appendNoItemList.filter(item => item.isSub == true)
      this.isShowMask = this.appendNoItemList.map(item => item.isSub).some(item => item == true)
    },
    showNoRemark(index) {
      this.appendNoItemList[index].isShowRemark = true
      this.$forceUpdate()
    },
    hideNoRemark(index) {
      this.appendNoItemList[index].isShowRemark = false
      this.$forceUpdate()
    },
    // 可叠加的
    clickDiscountItem(item, index) {
      this.firstClick = this.firstClick + 1
      this.appendItemList[index].isSub = this.appendItemList[index].isSub == true ? false : true
      this.appendNoItemList.forEach(item => (item.isSub = false))
      this.selectList = []
      this.selectList = this.appendItemList.filter(item => item.isSub == true)
      this.$forceUpdate()
    },
    showRemark(index) {
      this.appendItemList[index].isShowRemark = true
      this.$forceUpdate()
    },
    hideRemark(index) {
      this.appendItemList[index].isShowRemark = false
      this.$forceUpdate()
    },
    submitDiscount() {
      this.dialogVisible = false
      if (this.firstClick == 0) {
        if (this.selectList.length == 0) {
          // 解决第一次点击弹框不做操作时,但是有选择的数据。点击后优惠没有
          this.selectList = this.selectShowList
        }
      }
      this.selectList = this.selectList.sort((a, b) => a.discountNum - b.discountNum)
      this.$emit('submitDiscount', this.selectList)
    }
  }
}
</script>
<style lang="scss" scoped>
.discount-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .discount-item {
    width: 347px;
    height: 98px;
    padding: 12px;
    background: linear-gradient(90deg, #ffd2bf 0%, #fee5db 100%);
    border-radius: 6px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    .mask-box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0.46;
    }
    .discount-item-top {
      display: flex;
      padding-bottom: 6px;
      border-bottom: 1px dashed #c25a5a;
      .discount-info {
        width: calc(100% - 48px);
        margin-left: 8px;
        position: relative;
        .discount-title {
          width: 200px;
          color: #540610;
          font-weight: bold;
          margin-bottom: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .discount-time {
          font-size: 12px;
          color: #bb6b6b;
        }
        .discount-num {
          position: absolute;
          top: -8px;
          right: 30px;
          font-size: 22px;
          color: #df001d;
          span {
            font-size: 14px;
          }
        }
        .act-icon {
          color: #369eff;
          font-size: 16px;
          position: absolute;
          top: -1px;
          right: 1px;
          width: 14px;
          height: 14px;
        }
        .act-icon-no {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 14px;
          height: 14px;
          border-radius: 25px;
          border: 1px solid #333;
        }
      }
    }
    .discount-item-btm {
      display: flex;
      justify-content: space-between;
      padding-top: 9px;

      .remark {
        font-size: 11px;
        color: #bb6b6b;
      }
      .remark-act {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .expand-plantform {
      width: 100%;
      max-height: 190px;
      overflow-y: auto;
      white-space: pre-wrap;
      &::-webkit-scrollbar {
        width: 6px; /* 设置滚动条宽度 */
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(90deg, #ffd2bf 0%, #fee5db 100%); /* 滚动条轨道颜色 */
      }
      &::-webkit-scrollbar-thumb {
        background: #bb6b6b; /* 滚动条滑块颜色 */
        border-radius: 25px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #bb6b6b; /* 滚动条滑块hover颜色 */
      }
    }
    .expand-company {
      width: 100%;
      max-height: 190px;
      overflow-y: auto;
      white-space: pre-wrap;
      &::-webkit-scrollbar {
        width: 6px; /* 设置滚动条宽度 */
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(90deg, rgb(255, 224, 191) 0%, rgb(254, 238, 219) 100%); /* 滚动条轨道颜色 */
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(187, 140, 107); /* 滚动条滑块颜色 */
        border-radius: 25px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(187, 140, 107); /* 滚动条滑块hover颜色 */
      }
    }
  }
  .discount-item-height {
    height: auto;
  }
}
.dialog-footer-btn {
  width: 260px;
  height: 50px;
  margin: auto;
  color: #fff;
  line-height: 50px;
  text-align: center;
  background: #369eff;
  border-radius: 4px;
  cursor: pointer;
}
</style>
