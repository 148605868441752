<template>
  <div class="voucher-show-box" :style="showTab ? '' : 'padding: 20px 30px'">
    <div class="voucher-show-title">
      <span class="voucher-show-title-left">兑换券兑换</span>
      <div class="voucher-show-title-right">
        <span v-show="activeName == '0' && voucherList.length == 0">暂无可用兑换券</span>
        <!--  && actVoucherIds.length > 0 -->
        <span v-show="activeName == '0' && voucherList.length > 0">{{ actVoucherIds.length + '/' + needNum * num }}</span>
        <!-- <i :class="showTab ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="showTab = !showTab"></i> -->
      </div>
    </div>
    <div class="voucher-show-tab" v-show="showTab">
      <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="可用兑换券" name="0"></el-tab-pane>
        <el-tab-pane label="不可用兑换券" name="1"></el-tab-pane>
      </el-tabs> -->
      <div class="voucher-usable">
        <div class="voucher-list-box" v-if="!loading && !visilbeNoData">
          <div
            class="voucher-list-item"
            :class="activeName == '0' ? (item.isDisable ? 'expire-bac-item' : '') : 'expire-bac-item'"
            v-for="(item, index) in voucherList"
            :key="index"
          >
            <div class="voucher-list-content" :style="activeName == '1' ? 'cursor: no-drop;' : ''" @click="handleClickVoucherItem(item, index)">
              <div class="act-content" v-show="item.actItem && activeName == '0'"><i class="el-icon-success"></i></div>
              <div class="voucher-list-content-left expire-bac-left" :class="item.actItem ? 'act-item-left' : ''">
                <div class="circle-item">
                  <img :src="item.voucherIcon" alt="" srcset="" class="voucher-img" />
                </div>
              </div>
              <div class="voucher-list-content-right expire-bac-right" :class="item.actItem ? 'act-item-right' : ''">
                <div class="voucher-name">{{ item.voucherName }}</div>
                <div class="voucher-sign">券编号：{{ item.voucherCode }}</div>
                <div class="voucher-time">
                  <div class="voucher-expire">有效期：{{ item.expireTime }}</div>
                </div>
                <div class="icon" v-if="item.voucherDescription !== '' && item.voucherDescription">
                  <i class="el-icon-arrow-down" v-if="!item.visibleRemark" @click="onHandleDownRemark(item, index)"></i>
                  <i class="el-icon-arrow-up" v-else @click="onHandleUpRemark(item, index)"></i>
                </div>
              </div>
            </div>
            <!-- <img src="../../../../../assets/images/used-bac.png" alt="" class="used-bac" v-if="activeName == 'used'" /> -->
            <!-- <img src="../../../../../assets/images/expire-bac.png" alt="" class="expire-bac" v-if="activeName == 'expired'" /> -->
            <div class="voucher-remark" v-if="item.visibleRemark">说明：{{ item.voucherDescription ? item.voucherDescription : '暂无说明' }}</div>
          </div>
        </div>
        <div class="no-data" v-if="!loading && visilbeNoData">
          <img src="../../../assets/voucher-no-data.png" alt="" />
          <span>{{ activeName == '0' ? '暂无可以使用的兑换券' : '暂无兑换券' }}</span>
        </div>
        <div class="loading-box" v-if="loading" v-loading="loading" element-loading-text="加载中..."></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getVoucherState } from '@/api/confirmOrder.js'
export default {
  name: 'VoucherShow',
  props: {
    payType: { type: Boolean, default: false },
    groupId: { type: String, default: null },
    num: { type: Number, default: 1 },
    groupVouchers: {
      type: Array,
      default: () => []
    },
    needVoucherItem: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    num: {
      handler(newVal) {
        this.voucherAllList.forEach(item => {
          item.visibleRemark = false
          item.noNeed = true
          item.actItem = false
          item.isDisable = false
        })
        this.actVoucherIds = []
        this.actVoucherNeedIds = []
        this.$emit('handleClickVoucherItem', this.actVoucherIds, this.needNum * this.num)
      },
      deep: true
    },
    needVoucherItem: {
      handler(newVal) {
        if (newVal) {
          if(!this.$route.query.isJupm){
            this.voucherAllList.forEach(item => {
              item.visibleRemark = false
              item.noNeed = true
              item.actItem = false
              item.isDisable = false
            })
            // 5699 bug
            this.needNum = newVal.voucherCount || 0 * this.num
            this.actVoucherIds = []
            this.actVoucherNeedIds = []
            this.$emit('handleClickVoucherItem', this.actVoucherIds, this.needNum * this.num)
            this.voucherList = this.voucherAllList.filter(item => item.voucherId == newVal.voucherId)
            this.visilbeNoData = this.voucherList.length == 0 ? true : false
          }else{
            this.getList(newVal)
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      activeName: '0',
      showTab: true,
      voucherList: [],
      voucherAllList: [], // 所有兑换券数据
      queryParams: {
        pageNum: 1,
        pageSize: 9999,
        state: 0
      },
      actVoucherIds: [],
      actVoucherNeedIds: [],
      loading: false,
      visilbeNoData: false,
      needNum: 0, // 需要的数量
      voucherNeedList: [], // 可以选择的兑换券数据
      needItem: {}
    }
  },
  created() {
    if(!this.$route.query.isJupm){
      this.getList()
    }
  },
  methods: {
    handleClick() {
      this.getList()
      this.actVoucherIds = []
      this.actVoucherNeedIds = []
      this.$emit('handleClickVoucherItem', this.actVoucherIds, this.needNum * this.num)
    },
    async getList(newVal) {
      try {
        this.loading = true
        let res = await getVoucherState(this.groupId, this.activeName)
        this.voucherAllList = res?.rows || []
        if (this.activeName == '0') {
          this.voucherAllList.forEach(item => {
            item.visibleRemark = false
            item.noNeed = true
            item.actItem = false
          })
        }
        if(this.$route.query.isJupm){
          this.$nextTick(() => {
            this.voucherAllList.forEach(item => {
              item.visibleRemark = false
              item.noNeed = true
              item.actItem = false
              item.isDisable = false
            })
            // 5699 bug
            this.needNum = newVal.voucherCount || 0 * this.num
            this.actVoucherIds = []
            this.actVoucherNeedIds = []
            this.$emit('handleClickVoucherItem', this.actVoucherIds, this.needNum * this.num)
            this.voucherList = this.voucherAllList.filter(item => item.voucherId == newVal.voucherId)
            this.visilbeNoData = this.voucherList.length == 0 ? true : false
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        this.visilbeNoData = true
      }
    },
    handleClickVoucherItem(item, index) {
      if (this.payType && this.activeName == '0') {
        this.$message({
          message: '兑换券和余额不可同时使用!',
          type: 'warning'
        })
        return
      }
      if (this.activeName == '0' && item.noNeed) {
        if (this.actVoucherIds.length == 0 ? true : this.actVoucherIds.length < this.needNum * this.num || this.actVoucherIds.includes(item.id)) {
          // 设置被选中的边框对号特殊样式
          this.voucherList[index].actItem = !this.voucherList[index].actItem
          this.actVoucherIds = []
          this.actVoucherNeedIds = []
          this.voucherList.forEach(act => {
            if (act.actItem) {
              this.actVoucherIds.push(act.id)
              this.actVoucherNeedIds.push(act.voucherId)
            }
          })
          this.$forceUpdate()
          this.$emit('handleClickVoucherItem', this.actVoucherIds, this.needNum * this.num)
        }
        // 数量足够时灰掉其余兑换券
        if(this.actVoucherIds.length == this.needNum * this.num){
          this.voucherList.forEach(item => {
            if(!item.actItem){
              this.$set(item, 'isDisable', true)
            }
          })
        }else{
          this.voucherList.forEach(item => {
            if(item.isDisable){
              this.$set(item, 'isDisable', false)
            }
          })
        }
      }
    },
    onHandleDownRemark(value, index) {
      this.voucherList[index].visibleRemark = true
      this.$forceUpdate()
    },
    onHandleUpRemark(value, index) {
      this.voucherList[index].visibleRemark = false
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
.voucher-show-box {
  background: #f9fafb;
  border: 1px solid #f1f1f1;
  padding: 30px;
  .voucher-show-title {
    display: flex;
    justify-content: space-between;
    &-left {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
    }
    &-right {
      i {
        color: #9e9e9e;
        margin-left: 15px;
        cursor: pointer;
      }
      span {
        font-size: 16px;
        color: #666666;
      }
    }
  }
  .voucher-show-tab {
    padding-top: 20px;
    ::v-deep.el-tabs__header {
      margin: 0;
    }
    ::v-deep.el-tabs__item {
      color: #999;
    }
    ::v-deep.el-tabs__item.is-active {
      background: #fff;
      color: #333333;
    }
    .voucher-usable {
      background: #f9fafb;
      // padding: 20px 2px 20px 20px;
      // border-left: 1px solid #e4e7ed;
      // border-bottom: 1px solid #e4e7ed;
      // border-right: 1px solid #e4e7ed;
      .voucher-list-box {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        max-height: 260px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 3px;
          background-color: #d9d9d9;
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px #fff;
          background: #ededed;
          border-radius: 10px;
        }
        .voucher-list-item {
          box-sizing: border-box;
          width: calc(100% / 3 - 18px);
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          position: relative;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .voucher-list-content {
            display: flex;
            height: 120px;
            position: relative;
            cursor: pointer;
            .act-content {
              position: absolute;
              z-index: 2;
              right: 9px;
              top: 2px;
              color: #ff7e36;
              font-size: 22px;
            }
            &-left {
              box-sizing: border-box;
              width: 114px;
              background: #fff3dc;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              position: relative;
              //   overflow: hidden; /* 隐藏溢出的部分 */
              display: flex;
              justify-content: center;
              align-items: center;
              &::before {
                content: '';
                position: absolute;
                top: -1px;
                right: -1px;
                width: 14px;
                height: 14px;
                background-color: #fff;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 50px;
              }
              &::after {
                content: '';
                position: absolute;
                bottom: -1px;
                right: -1px;
                width: 14px;
                height: 14px;
                background-color: #fff;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 0px;
              }
              .circle-item {
                box-sizing: border-box;
                width: 71px;
                height: 71px;
                border-radius: 50%;
                background: #fff;
                padding: 10px 10px;
                .voucher-img {
                  width: 50px;
                  height: 50px;
                  object-fit: contain;
                }
              }
            }
            .act-item-left {
              border: 1px solid #ff7711;
              &::before {
                border-left: 1px solid #ff7711;
                border-bottom: 1px solid #ff7711;
              }
              &::after {
                border-left: 1px solid #ff7711;
                border-top: 1px solid #ff7711;
              }
            }
            &-right {
              box-sizing: border-box;
              width: 238px;
              margin-left: 2px;
              background: linear-gradient(90deg, #fff3dc 0%, #fde5c0 100%);
              position: relative;
              //   overflow: hidden;
              padding: 13px 16px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                width: 14px;
                height: 14px;
                background-color: #fff;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 50px;
              }
              &::after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 14px;
                height: 14px;
                background-color: #fff;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 0px;
              }
              .voucher-name {
                font-weight: 600;
                font-size: 16px;
                color: #653b10;
                word-break: break-all;
              }
              .voucher-sign {
                font-weight: 400;
                font-size: 12px;
                color: #6e4216;
                word-break: break-all;
              }
              .voucher-time {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .voucher-expire {
                  font-weight: 400;
                  font-size: 12px;
                  color: #653b10;
                  word-break: break-all;
                }
                .go-web {
                  color: #da6d00;
                  font-weight: 400;
                  font-size: 12px;
                  padding: 2px 9px;
                  border-radius: 40px;
                  border: 1px solid #da6e00;
                  cursor: pointer;
                  flex-shrink: 0;
                }
              }
              .icon {
                position: absolute;
                right: 20px;
                bottom: 0px;
                color: #da6d00;
                ::v-deep .el-icon-arrow-down {
                  font-size: 14px;
                  cursor: pointer;
                }
                ::v-deep .el-icon-arrow-up {
                  font-size: 14px;
                  cursor: pointer;
                }
              }
            }
            .act-item-right {
              border: 1px solid #ff7711;
              &::before {
                border-right: 1px solid #ff7711;
                border-bottom: 1px solid #ff7711;
              }
              &::after {
                border-right: 1px solid #ff7711;
                border-top: 1px solid #ff7711;
              }
            }
          }
          .voucher-remark {
            box-sizing: border-box;
            word-break: break-all;
            background: #f6f8fc;
            border-radius: 10px 10px 10px 10px;
            padding: 5px 10px;
            font-weight: 400;
            font-size: 10px;
            color: #a09292;
            margin-top: 3px;
            // position: absolute;
            // bottom: -40px;
          }
          .used-bac {
            position: absolute;
            right: 0;
            width: 56px;
            height: 65px;
          }
          .expire-bac {
            position: absolute;
            right: 0;
            width: 56px;
            height: 65px;
          }
        }
        .expire-bac-item {
          .voucher-list-content {
            cursor: no-drop;
          }
          .expire-bac-left {
            background: #ededed;
          }
          .expire-bac-right {
            background: linear-gradient(90deg, #ededed 0%, #dfdfdf 100%);
            .voucher-name {
              color: #3b3b3b;
            }
            .voucher-sign {
              color: #424242;
            }
            .voucher-time {
              .voucher-expire {
                color: #3b3b3b;
              }
            }
            .icon {
              color: #3b3b3b;
            }
          }
        }
        @media screen and (min-width: 1280px) and (max-width: 1440px) {
          .voucher-list-item {
            width: calc(100% / 3 - 20px);
          }
        }
      }
      .no-data-box {
        text-align: center;
        color: #a09292;
        margin-top: 200px;
      }
    }
    .no-data {
      height: 260px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        display: inline-block;
        margin-top: 9px;
        font-size: 16px;
        color: #d3dcde;
      }
    }
    .loading-box ::v-deep {
      height: 260px;
      .el-loading-spinner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
